<template>
  <div class="excel-upload flex-box flex-column flex-center">
    <div class="upload-wrapper">
      <div class="uploader">
        <van-uploader
          ref="excelUploader"
          result-type="file"
          v-model="excelList"
          :accept="accept"
          :max-count="maxCount"
          multiple
          :upload-text="uploadText"
          :before-read="beforeRead"
        ></van-uploader>
      </div>
    </div>
    <div class="submit-btn flex-box flex-column">
      <van-button
        class="task-submit"
        :loading="loading"
        loading-text="提交中..."
        @click="handleSubimt"
      >点我提交</van-button>
    </div>
    <div class="history-submit" @click="showHistory">查看历史记录</div>
  </div>
</template>
<script>
import { LoginTypeEnum } from '@/apis/config';
import { Toast, Dialog } from 'vant'
import axios from 'axios'
import { excelSubmit, queryHistorySubmit } from '@/apis'
import { getCookie } from '@/tools/cookie'
import { invalidateToken, getToken } from '@/tools/request'
export default {
  name: 'ExcelUpload',
  props: {
    loginType: {
      type: Number,
      default: LoginTypeEnum.USER_REGISTER_ASSIGN
    },
    maxCount: {
      type: Number,
      default: 1
    },
    accept: {
      type: String,
      default: '.xlsx'
    },
    uploadText: {
      type: String,
      default: '只允许上传一张.xlsx后缀文件'
    }
  },
  data() {
    return {
      loading: false,
      excelList: [],
      channelCodeCache: '',
      loginTypeCache: ''
    }
  },
  created() {
    this.channelCodeCache = getCookie('channelCode')
    this.loginTypeCache = getCookie('loginType')
  },
  methods: {
    /**
     * @description: 提交上传文件
     * @return {*}
     */    
    async handleSubimt() {
      if (!this.excelList.length) {
        Toast.fail({
          duration: 2000,
          message: '请先选择要上传的.xlsx文件'
        })
        return;
      }
      this.loading = true;
      let uploadExcel = this.excelList[0]
      uploadExcel.status = "uploading"
      uploadExcel.message = "上传中..."
      const params = {
        loginType: this.loginType,
        file: uploadExcel.file
      }
      let formData = new FormData();
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });
      try {
        const token = getToken()
        const res = await axios({
          url: excelSubmit,
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data;",
            "token": token,
          },
          withCredentials: false,
        });
        if (res && res.status === 200) {
          this.onUploadSuccess(uploadExcel, res.data)
        } else {
          this.onUploadError(uploadExcel)
        }
      } catch(e) {
        this.onUploadError(uploadExcel)
      } finally {
        this.loading = false
      }
    },
    loginFailHandle() {
      if (!this.channelCodeCache || !this.loginTypeCache) {
        Toast.fail("登录失效，请重新扫码登录")
      } else {
        this.$router.push({
          path: `/login?channelCode=${this.channelCodeCache}&loginType=${this.loginType}`,
          replace: true,
        })
      }
    },
    /**
     * @description: 文件上传成功之后的处理逻辑
     * @param {*} file 上传的文件用于处理状态
     * @param {*} res 上传成功之后的message信息
     * @return {*}
     */    
    async onUploadSuccess(file, res) {
      if (res.code === 107) {
        invalidateToken()
        this.loginFailHandle()
        return;
      }
      file.status = 'done'
      file.message = ''
      const { showMessage = [] } = res?.data || {}
      const options = {
        title: res.code === 0 ? '处理成功' : '处理失败',
        message: showMessage.join('\n'),
        confirmButtonText: '关闭',
      }
      await Dialog.alert(options);
      if (res.code === 0) {
        this.$router.go(0)
      }
    },
    /**
     * @description: 上传失败之后的处理逻辑
     * @param {*} file
     * @return {*}
     */    
    onUploadError(file) {
      file.status = 'failed'
      file.message = '失败重新提交'
    },
    /**
     * @description: 展示历史提交上传信息
     * @return {*}
     */    
    async showHistory() {
      try {
        const res = await queryHistorySubmit({
          loginType: this.loginType
        })
        if (res && res.code === 0) {
          const { showMessage = [], submitTime = '' } = res.data || {};
          Dialog.alert({
            title: `处理时间：${submitTime}`,
            message: showMessage.join('\n'),
            confirmButtonText: '关闭'
          })
        } else {
          if (res.code === 107) {
            invalidateToken()
            this.loginFailHandle()
            return;
          } else {
            Toast.fail({
              duration: 2000,
              message: res.msg || '获取历史记录失败，请稍后重试'
            })
          }
        }
      } catch(e) {
        Toast.fail({
          duration: 2000,
          message: '获取历史记录失败，请稍后重试'
        })
      }
    },
    /**
     * @description: 选中文件时进行校验
     * @return {*}
     */    
    beforeRead(file) {
      const fileType = file.name.split('.')[1];
      if (fileType !== 'xlsx') {
        Toast.fail({
          duration: 2000,
          message: '请上传.xlsx格式文件'
        })
        return false
      }
      return true
    },
  }
}
</script>
<style lang="less" scoped>
.excel-upload {
  margin: 20px;
  font-size: 16px;
  .upload-wrapper {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e2e2e3;
    border-radius: 20px;
    /deep/.van-uploader__upload {
      width: 100%;
      height: 80px;
      border-radius: 20px;
      margin: 0;
    }
    /deep/.van-uploader {
      width: 100%;
    }
    /deep/.van-uploader__preview {
      margin: 0;
    }
  }
  .submit-btn {
    justify-content: center;
    width: 100%;
    margin-top: 50px;
    .task-submit {
      width: 100%;
      color: #fff;
      background-color: #007aff;
      border-radius: 16px;
    }
  }
  .history-submit {
    text-align: center;
    padding: 10px;
  }
}

</style>